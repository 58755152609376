import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import Script from 'next/script';
import { ContactForm } from 'ui/components/3-organisms/ContactForm/ContactForm';

export const FormFeature: React.FC<Content.Form> = ({ content }) => {
	const kicker = {
		firstWord: content.properties.kickerFirstWord,
		secondWord: content.properties.kickerSecondWord,
	};
	const dictionary = useDictionaryContext();
	const selectButtonLabel = dictionary.getValue('Form.SelectButtonLabel');
	return (
		<>
			<Script strategy="beforeInteractive" id="ortto">
				{`	{window.ap3c = window.ap3c || {};
					var ap3c = window.ap3c;
					ap3c.cmd = ap3c.cmd || [];
					ap3c.cmd.push(function() {
						ap3c.init('Y5Qi-PBZ0OccFIOeaW5vdm9hcw', 'https://capture-api-us.ortto.app/');
						ap3c.track({v: 0});
					});
					ap3c.activity = function(act) { ap3c.act = (ap3c.act || []); ap3c.act.push(act); };
					var s, t; s = document.createElement('script'); s.type = 'text/javascript'; s.src = "https://cdn2l.ink/app.js";
					t = document.getElementsByTagName('script')[0]; t.parentNode.insertBefore(s, t);}`}
			</Script>

			<ContactForm
				{...content?.properties}
				trumpetHeading={kicker.firstWord ?? kicker.secondWord}
				bgColor={content?.properties.color}
				showImage={content.properties.showImage}
				selectButtonLabel={selectButtonLabel}
			/>
		</>
	);
};
