import classNames from 'classnames';
import styles from './ContactForm.module.scss';
import { Heading } from 'ui/components/1-atoms/Heading/Heading';
import { Picture } from 'ui/components/1-atoms/Media/Picture/Picture';
import { Container } from 'ui/components/4-habitats/Container/Container';
import { TopLevelGrid } from 'ui/components/4-habitats/TopLevelGrid/TopLevelGrid';
import { TopLevelGridCell } from 'ui/components/4-habitats/TopLevelGrid/TopLevelGridCell';
import { Grid } from 'ui/components/4-habitats/Grid/Grid';
import { GridCell } from 'ui/components/4-habitats/Grid/GridCell';
import ColorOptions from 'helpers/types';
import { useEffect } from 'react';

export interface ContactFormProps {
	bgColor?: ColorOptions;
	trumpetHeading?: string;
	heading: string;
	image: {
		url: string;
		altText?: string;
	};
	formHtmlCode: string;
	formProps?: {
		firstNamePlaceholder: string;
		lastNamePlaceholder: string;
		emailPlaceholder: string;
		termsAndConditionsLabel?: string;
		termsAndConditionsHeadingLabel?: string;
		signupLabel: string;
	};
	showImage?: boolean;
	selectButtonLabel?: string;
}
//todo rename to only Form - as this is not contact specific
export const ContactForm: React.FC<ContactFormProps> = ({ bgColor = 'white', trumpetHeading, heading, image, formHtmlCode, showImage = true, selectButtonLabel }) => {
	useEffect(() => {
		const handleFormInjection = () => {
			const formContainer = document.querySelector('.ortto');

			if (formContainer) {
				const selectElements = formContainer.querySelectorAll('select');

				selectElements.forEach((selectElement) => {
					const button = document.createElement('button');
					button.className = 'select-all-options-button';
					button.textContent = selectButtonLabel;

					button.onclick = function () {
						toggleAllOptions(selectElement);
					};
					selectElement.parentNode.appendChild(button);
				});
			}
		};

		handleFormInjection();
	}, [formHtmlCode]);

	function toggleAllOptions(selectElement: HTMLSelectElement) {
		const options = selectElement.options;

		for (let i = 0; i < options.length; i++) {
			options[i].selected = true;
		}

		const event = new Event('change', { bubbles: true });
		selectElement.dispatchEvent(event);
	}

	return (
		<div className={classNames(styles.ContactForm, styles[`u-color___${bgColor}`])}>
			<Container width="Standard">
				<TopLevelGrid container wrap>
					<TopLevelGridCell desktopWidth="16col" mobileWidth="100">
						{trumpetHeading && <p className={styles.ContactForm_trumpetHeading}>{trumpetHeading}</p>}

						{heading && (
							<Heading className={styles.ContactForm_heading} headingLevel="h2" style="xl">
								{heading}
							</Heading>
						)}
					</TopLevelGridCell>

					{showImage && (
						<TopLevelGridCell desktopWidth="22col" mobileWidth="100" desktopOffset="0col">
							<Grid container wrap largeGutter>
								<GridCell desktopWidth="66" mobileWidth="100">
									{image && (
										<Picture
											className={styles.ContactForm_image}
											url={image.url}
											altText={image.altText}
											sizes="(max-width: 768px) 100vw, (max-width: 1728px) 66vw, 1150px"
										/>
									)}
								</GridCell>
								<GridCell desktopWidth="33" mobileWidth="100" className={classNames('ortto')}>
									<div dangerouslySetInnerHTML={{ __html: formHtmlCode }}></div>
								</GridCell>
							</Grid>
						</TopLevelGridCell>
					)}

					{!showImage && (
						<TopLevelGridCell desktopWidth="24col" mobileWidth="100" desktopOffset="0col">
							<Grid container wrap>
								<GridCell desktopWidth="100" mobileWidth="100" className={classNames('ortto', 'ortto-noImage')}>
									<div dangerouslySetInnerHTML={{ __html: formHtmlCode }}></div>
								</GridCell>
							</Grid>
						</TopLevelGridCell>
					)}
				</TopLevelGrid>
			</Container>
		</div>
	);
};
